export enum ProgramField {
	TITLE = "title",
	DESCRIPTION = "description",
	TYPE = "type",
}

export enum ProgramSnapshotField {
	ID = "id",
	title = "title",
}

export enum ProgramType {
	CONTEST = "CONTEST",
}

export interface ProgramData {
	[ProgramField.TITLE]?: string,
	[ProgramField.DESCRIPTION]?: string,
	[ProgramField.TYPE]?: ProgramType,
} 

export interface ProgramSnapshot {
	[ProgramSnapshotField.ID]?: string,
	[ProgramSnapshotField.title]?: ProgramData[ProgramField.TITLE],
}