import firebase from "firebase/app";
import { database } from "../../utils/init";
import { ProgramSnapshot } from "./program";
import { UserSnapshot } from "./user";

// certificates are the proof of achievement. Ergo, if an achievement exists, that certificate is valid

export const ACHIEVEMENT_COLLECTION_ID = "achievements";

export enum AchievementTitle {
	THE_BEST = "THE_BEST",
	NOMINEE = "NOMINEE",
	TITANIUM = "TITANIUM",
	PLATINIUM = "PLATINUM",
	GOLD = "GOLD",
	SILVER = "SILVER",
	BRONZE = "BRONZE",
	PARTICIPATION = "PARTICIPATION",
	WINNER = "WINNER",
	FIRST = "FIRST",
	SECOND = "SECOND",
	THIRD = "THIRD",
	FOURTH = "FOURTH",
	FIFTH = "FIFTH",
}

export enum AchievementAs {
	CA = "CA",
	PHOTOGRAPHER = "PHOTOGRAPHER",
}

export enum AchievementField {
	USER = "user",
	PROGRAM = "program",
	TITLE = "title",
	AS = "as",
	CERTIFICATE_MESSAGE = "certified_message",
	DATE = "date",
}

export function getAchievements() {
	return database().collection(ACHIEVEMENT_COLLECTION_ID);
}

export function getAchievement(id: string) {
	return getAchievements().doc(id);
}

export interface AchievementData {
	[AchievementField.USER]?: UserSnapshot,
	[AchievementField.PROGRAM]?: ProgramSnapshot,
	[AchievementField.TITLE]?: AchievementTitle,
	[AchievementField.AS]?: AchievementAs,
	[AchievementField.CERTIFICATE_MESSAGE]?: string,
	[AchievementField.DATE]?: firebase.firestore.Timestamp,
}