import { ActionButton, IIconProps, IStackTokens, Separator, Stack } from "@fluentui/react";
import { useConst } from "@fluentui/react-hooks";
import { formatDate, MaskedTextField, RawClassSet, reportDebug, reportError, SectionFormControl, SectionStatus, Text, useBackground, useClasses } from "@hashimukh/client-utils-js";
import React, { useCallback, useMemo, useState } from "react";
import { AppTheme } from "./App";
import { AppPage } from "./AppPage";
import { LEN_CERTIFICATE_ID } from "./db/constants";
import { AchievementAs, AchievementData, AchievementField, AchievementTitle, getAchievement } from "./db/models/achievement";
import { ProgramSnapshotField } from "./db/models/program";
import { UserSnapshotField } from "./db/models/user";
import { NameField } from "./db/objects/name";
import { toProperWord } from "./utils/strings";

const DESCRIPTION_PAGE = "Validate your certificates' authenticity that you received for participating in Hashimukh Creators Program.";
const SEP_ID_SEGMENT = "-";

const getClasses: (theme: AppTheme) => RawClassSet<"validationRoot" | "validationHeader" | "validationHeaderContent" | "validationHeaderCaptionText" | "validationBody" | "changeIdButton" | "separator"> = () => ({
	validationRoot: {

	},
	validationHeader: {
		width: "100%",
	},
	validationHeaderContent: {

	},
	validationHeaderCaptionText: {
		paddingBottom: 0,
	},
	changeIdButton: {
		height: "min-content",
	},
	separator: {
		width: "100%",
	},
	validationBody: {

	}
})

const tokens: Record<"root" | "validationHeaderCaptions", IStackTokens> = {
	root: {
		childrenGap: 16,
	},
	validationHeaderCaptions: {
		childrenGap: 12,
	}
}

const editIcon: IIconProps = {
	iconName: "Edit",
}

function simplifyId(id: string | undefined) {
	return (id?.match(/.{1,3}/g) || []).join(SEP_ID_SEGMENT);
}

function deSimplifyId(id: string | undefined) {
	return id?.trim().toLowerCase().replace(/[-]/g, "");
}

const ValidMessage: React.FunctionComponent<ValidMessageProps> = (props) => {
	const {
		data,
	} = props;

	const customMessage = data[AchievementField.CERTIFICATE_MESSAGE];
	if (customMessage) return <Text>customMessage</Text>;

	const awardeeName = data[AchievementField.USER]?.[UserSnapshotField.NAME]?.[NameField.DISPLAY_NAME];
	const nameElement = awardeeName && <> to <b>{awardeeName}</b></>;

	const issueDate = data[AchievementField.DATE];
	const dateElement = issueDate && <> on {formatDate(issueDate.toDate(), "long")}</>;
	
	const title = data[AchievementField.TITLE];
	let titleElement;
	switch (title) {
		case AchievementTitle.THE_BEST:
			titleElement = <> for the recognition <i>The Best</i></>;
			break;
		case AchievementTitle.NOMINEE:
			titleElement = <> for the recognition <i>Nominee</i></>;
			break;
		case AchievementTitle.PARTICIPATION:
			titleElement = <> for participating</>;
			break;
		case AchievementTitle.TITANIUM:
		case AchievementTitle.PLATINIUM:
		case AchievementTitle.GOLD:
		case AchievementTitle.SILVER:
		case AchievementTitle.BRONZE:
			titleElement = <> for winning the <i>{toProperWord(title)}</i> medal</>;
			break;
		case AchievementTitle.WINNER:
			titleElement = <> for <i>winning</i></>
			break;
		case AchievementTitle.FIRST:
		case AchievementTitle.SECOND:
		case AchievementTitle.THIRD:
		case AchievementTitle.FOURTH:
		case AchievementTitle.FIFTH:
			titleElement = <> for becoming <i>{title.toLowerCase()}</i></>;
			break;
	}

	const as = data[AchievementField.AS];
	let asElement;
	switch (as) {
		case AchievementAs.CA:
			asElement = <> as a Campus Ambassador</>;
			break;
		case AchievementAs.PHOTOGRAPHER:
			asElement = <> in photography</>;
			break;
	}

	const program = data[AchievementField.PROGRAM]?.[ProgramSnapshotField.title];
	const programElement = program && <> in <i>{program}</i></>;

	return <Text>This certificate was issued{nameElement}{dateElement}{titleElement}{asElement}{programElement}.</Text>
}

export const ValidateCert: React.FunctionComponent = () => {
	const bg = useBackground();
	const classes = useClasses(getClasses);

	const [id, setId] = useState<string>();
	const [result, setResult] = useState<{ status: "success" | "failed" | "error", message: string | JSX.Element }>();

	const idError = useMemo(() => {
		if (id?.length === LEN_CERTIFICATE_ID) return "";
		return "Invalid ID"
	}, [id]);

	const [idMask, idPlaceHolder] = useConst(() => {
		let mask = "";
		let placeholder = "";
		for (let i = 0; i < LEN_CERTIFICATE_ID; i++) {
			mask += "*";
			placeholder += "x";
		}

		return [simplifyId(mask), simplifyId(placeholder)];
	})

	const handleIdChange = useCallback((_evt: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined) => {
		setId(deSimplifyId(newValue));
	}, []);

	const onValidate = useCallback(async () => {
		if (!id) return;

		const achievement = getAchievement(id);
		let achievementData: AchievementData | undefined;
		try {
			const snapshot = await achievement.get();
			if (snapshot.exists) achievementData = snapshot.data();
		} catch (err) {
			reportError(`unable to get achievement`, err);
			setResult({
				status: "error",
				message: "Something went wrong from our end. Please try again later.",
			})
			return;
		}

		if (!achievementData) {
			setResult({
				status: "failed",
				message: "We couldn't find a matched certificate in our system. Please make sure that Certificate ID is typed correctly.",
			})
			return;
		}

		reportDebug(`achievement data: [${JSON.stringify(achievementData, undefined, 2)}]`);
		
		setResult({
			status: "success",
			message: <ValidMessage data={achievementData}/>
		})
		return undefined;
	}, [id]);

	const changeId = useCallback(() => {
		setResult(undefined);
	}, []);

	return <AppPage
		pageType="narrow"
		background={bg}
		heading="Validate Certificate"
		tokens={tokens.root}
		horizontalAlign="center"
		metas={{
			title: "Certificate - Validate",
			description: DESCRIPTION_PAGE,
		}}
	>
		{result 
			?  <>
				<Stack className={classes.validationHeader}>
					<Text type="h1">{result.status === "success" ? "Valid certificate" : "Couldn't validate certificate"}</Text>
					<Stack horizontal tokens={tokens.validationHeaderCaptions} wrap>
						<Text type="caption" className={classes.validationHeaderCaptionText}>{`Certificate ID: ${simplifyId(id)}`}</Text>
						<ActionButton className={classes.changeIdButton} text="Change" iconProps={editIcon} onClick={changeId}/>
					</Stack>
					<Separator className={classes.separator} />
				</Stack>
				<Stack className={classes.validationBody}>
					<Text>{result.message}</Text>
				</Stack>
			</>
			: <SectionFormControl
				sections={[{
					id: "cert-id",
					status: id && !idError ? SectionStatus.FULFILLED : SectionStatus.INCOMPLETE,
					description: DESCRIPTION_PAGE,
					items: <>
						<MaskedTextField 
							label="Certificate ID"
							placeholder={idPlaceHolder}
							description="It should be visible at the top left corner of your certificate."
							value={id}
							mask={idMask}
							maskChar=""
							onChange={handleIdChange}
							errorMessage={idError}
							required
						/>
					</>
				}]} 
				progressProps={{
					submitText: "Validate",
					hideProgressIndicator: true,
				}}
				onSubmit={onValidate}
			/>}
	</AppPage>
}

interface ValidMessageProps {
	data: AchievementData,
}