import { Link, Text } from "@hashimukh/client-utils-js";
import React, { useEffect, useState } from "react";
import { AppPage } from "./AppPage";
import { URL_FACEBOOK_GROUP } from "./utils/constants";
import { getAnalytics } from "./utils/init";

const redirectDeferTime = 5;

export const Home: React.FunctionComponent = () => {
	const [countDown, setCountDown] = useState(redirectDeferTime);

	useEffect(() => {
		const id = setInterval(() => setCountDown(Math.max(countDown - 1, 0)), 1000);
		return () => clearInterval(id);
	}, [countDown]);

	useEffect(() => {
		getAnalytics().logEvent("desire_home");
	}, []);
	
	if (countDown <= 0) window.location.href = URL_FACEBOOK_GROUP;

	return <AppPage
		pageType="narrow"
		heading="Hashimukh Creators Program"
		subHeading="Host of country-wide creators award programs"
	>
		<Text>This is page is currently in development. We are redirecting to our Facebook <Link href={URL_FACEBOOK_GROUP}>group</Link> in {countDown}.</Text>
	</AppPage>
}