import { Metric } from "web-vitals"
import { getAnalytics } from "./init"

export const captureReport = (metric: Metric) => {
	getAnalytics().logEvent(metric.name, {
		// Built-in params:
		value: metric.delta, // Use `delta` so the value can be summed.
		// Custom params:
		metric_id: metric.id, // Needed to aggregate events.
		metric_value: metric.value, // Optional.
		metric_delta: metric.delta, // Optional.
	
		// OPTIONAL: any additional params or debug info here.
		// See: https://web.dev/debug-web-vitals-in-the-field/
		// metric_rating: 'good' | 'ni' | 'poor',
		// debug_info: '...',
		// ...
	})
}