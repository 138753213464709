import { NameData, NameField } from "../objects/name";

export enum UserField {
	NAME = "name",
}

export enum UserSnapshotField {
	ID = "id",
	NAME = "name",
}

export interface UserData {
	[UserField.NAME]?: NameData
}

export interface UserSnapshot {
	[UserSnapshotField.ID]?: string,
	[UserSnapshotField.NAME]?: Pick<NameData, NameField.DISPLAY_NAME>,
}