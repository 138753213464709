import { ThemeProvider } from '@fluentui/react';
import { HashimukhContext, HashimukhContextInterface, HashimukhSemanticColors, HashimukhTheme, hashimukhThemes, HashimukhThemes, PartialHashimukhTheme, Theme, useMediaQuery, useTheme } from '@hashimukh/client-utils-js';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Home } from './Home';
import { NoMatch } from './NoMatch';
import './res/styles/app.css';
import { ValidateCert } from './ValidateCert';

export const AppContext = HashimukhContext;
export const appThemes: AppThemes = hashimukhThemes;

export const App: React.FunctionComponent = () => {
  const isPrinting = useMediaQuery("print");
  const theme = useTheme();

  const [inPrintMode, setPrinitMode] = useState(isPrinting);

  return <AppContext.Provider value={{ inPrintMode: isPrinting || inPrintMode, setPrintMode: setPrinitMode }}>
    <ThemeProvider theme={theme.current === Theme.LIGHT ? appThemes.light : appThemes.dark}>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/validate-certificate">
            <ValidateCert />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  </AppContext.Provider>
};

export interface AppContextInterface extends HashimukhContextInterface {

}

export interface PartialAppTheme extends PartialHashimukhTheme {
    semanticColors?: Partial<AppSemanticColors>
}

export interface AppThemes extends HashimukhThemes {
    light: PartialAppTheme,
    dark: PartialAppTheme
}

export interface AppTheme extends HashimukhTheme {
    semanticColors: AppSemanticColors
}

export interface AppSemanticColors extends HashimukhSemanticColors {

}