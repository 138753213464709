import { mergeStyles } from '@fluentui/react';
import { Text } from '@hashimukh/client-utils-js';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import "./res/styles/index.css";
import { init } from './utils/init';

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

init().then(() => ReactDOM.render(<App />, document.getElementById('root')))
  .catch(() => ReactDOM.render(<Text>Something went wrong.</Text>, document.getElementById('root')));

reportWebVitals();
