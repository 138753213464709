import { initializeIcons } from "@fluentui/react";
import { initHashimukh, onEmulator, reportDebug } from "@hashimukh/client-utils-js";
import firebase from "firebase/app";
import { FABRICS_APP } from "../db/constants";
import reportWebVitals from "../reportWebVitals";
import { fabricsConfig } from "../res/configs";
import { captureReport } from "./reports";

require("firebase/firestore");
require("firebase/analytics");

let hasInit = false;

export const database = (app?: firebase.app.App) => app ? app.firestore() : firebase.firestore(firebase.app(FABRICS_APP));
export const getAnalytics = () => firebase.analytics(firebase.app(FABRICS_APP));

export async function init() {
	if (hasInit) return;
	hasInit = true;

	reportDebug(() => `initializing app`);

	const hsmInit = initHashimukh();
	
	initializeIcons();
	firebase.initializeApp(fabricsConfig, FABRICS_APP);

	const db = database();
	if (onEmulator) {
		db.useEmulator("localhost", 8280);
		getAnalytics().setAnalyticsCollectionEnabled(false);
	}

	reportWebVitals(captureReport);
	return hsmInit.then(() => {reportDebug(() => `app initialized`)})
}